<style lang="scss" scoped>
.input-wrap:nth-child(2) {
  margin-bottom: 20px !important;
}
@media (max-width: 768px){
  .resend-btn{
    width: 70% !important;
    margin-top: 10px;
  }
  .inputs-wrapper{
    margin-bottom: -18px !important;
  }
}
.close-btn {
  border-radius: 8px;
  background: linear-gradient(90deg, #1193a7 0%, #31bb38 100%);
  margin-top: 25px;
  padding: 12px 30px;
  text-align: center;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  font-family: jost;
  width: 100%;
}

#resendDialog {
  padding: 30px 50px;
  border-radius: 10px;
  background: #FFF;
  border: 0;
  margin: 0 auto;
  top: 30%;
  width: 470px;
  animation: fadeIn 1s ease both;
  &::backdrop {
    animation: fadeIn 1s ease both;
    opacity: 0.9;
    background: rgba(71, 71, 71, 0.70);
    z-index: 2;
  }
  .x {
    filter: grayscale(1);
    border: none;
    background: none;
    position: absolute;
    top: 12px;
    right: 12px;
    transition: ease filter, transform 0.3s;
    cursor: pointer;
    transform-origin: center;
    &:hover {
      filter: grayscale(0);
      transform: scale(1.1);
    }
  }
  .dialog__title {
    color: #000;
    font-family: Jost;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
  }
}
.inputs-wrapper {
  margin-bottom: 10px;
}
.bg-green {
  background: linear-gradient(
    90deg,
    rgba(17, 147, 167, 1) 0%,
    rgba(49, 187, 56, 1) 100%
  ) !important;
}
@media (max-width: 500px) {
  .data-text {
    align-items: center;
    padding: 10px 0px;
  }
}
</style>
<style lang="scss">
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}
.resend-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-size: 12px;
  padding: 10px 15px;
  font-weight: 500;
  border-radius: 8px;
  background: linear-gradient(
    90deg,
    rgba(17, 147, 167, 1) 0%,
    rgba(49, 187, 56, 1) 100%
  );
  position: relative;
  z-index: 1;
  transition: 0.4s all ease-in-out;
  cursor: pointer;
  width: 24%;
  margin-bottom: 10px;
}
.resend-btn:last-child {
  margin-bottom: 0;
}
.confirm-code-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-size: 14px;
  padding: 10px 15px;
  font-weight: 500;
  border-radius: 5px;
  background: linear-gradient(
    90deg,
    rgba(17, 147, 167, 1) 0%,
    rgba(49, 187, 56, 1) 100%
  );
  position: relative;
  z-index: 1;
  transition: 0.4s all ease-in-out;
  cursor: pointer;
  width: 24%;
  margin-top: 10px;
}
.ok-btn {
  text-align: center;
  color: white !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  border-radius: 5px !important;
  padding: 10px 15px !important;
  background: linear-gradient(
    90deg,
    rgba(17, 147, 167, 1) 0%,
    rgba(49, 187, 56, 1) 100%
  ) !important;
  z-index: 1;
  transition: 0.4s all ease-in-out;
}
.resend-btn:hover {
  color: #fff;
  text-decoration: none;
  opacity: 0.85;
}

.q-dialog__inner > .q-card > .q-card__actions .q-btn--rectangle {
  min-width: 80px !important;
}
.q-btn--dense.q-btn--round {
  min-height: 20px !important;
  min-width: 22px !important;
}
.q-card > div:not(.q--avoid-card-border) {
  margin-top: 0px !important;
}

.close-popup {
  left: 100% !important;
  top: -235% !important;
  background: #d9d9d9 !important;
  width: 9%;
  position: absolute !important;
  width: 15px !important;
  height: 15px !important;
  padding: 0px !important;
  .q-icon {
    font-size: 14px !important;
  }
  .col {
    padding: 0px !important;
  }
  img {
    margin-top: 3px !important;
  }
}
.q-virtual-scroll__content {
  border-radius: 8px;
  background: #fafafa;
}
.q-item__label {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 6px;
  padding-bottom: 6px;
}
.q-item {
  padding: 0 !important;
  min-height: 20px !important;
}
.q-item:hover {
  background: #f2f2f2;
}
.q-menu {
  box-shadow: none !important;
}
.q-virtual-scroll__content {
  margin-top: 6px !important;
}
.q-field__label {
  color: #8b8b8b !important;
}
.block {
  text-transform: none !important;
}
.q-btn--rounded {
  border-radius: 10px !important;
  .block {
    text-transform: none !important;
    padding-left: 25px;
    padding-right: 25px;
  }
}
.q-btn {
  padding: 4px 3px !important;
  font-size: 12px !important;
}
.q-field__label {
  font-size: 12px !important;
  top: 15px !important;
}
.q-field--auto-height .q-field__control {
  height: 50px !important;
  min-height: 50px !important;
}
.q-field__control {
  height: 50px !important;
  min-height: 50px !important;
}
.q-field--labeled .q-field__native {
  font-size: 12px !important;
}
.q-field__marginal {
  height: 50px !important;
}
.q-btn:before {
  box-shadow: none !important;
}
.q-dialog__inner > .q-card > .q-card__actions .q-btn--rectangle {
  border-radius: 10px;
}
@media (max-width: 470px) {
  .input {
    .q-field--outlined .q-field__control {
      padding: 0 18px 0 12px !important;
    }
  }
  .q-item__label {
    font-size: 16px !important;
    padding-left: 10px !important;
  }
  .q-field__append + .q-field__append {
    padding-left: 1px !important;
  }
  .close-popup {
    left: 99% !important;
    top: -375% !important;

    img {
      margin-top: 2px !important;
    }
  }
}
.q-card__actions--horiz {
  justify-content: center !important;
}
.q-field__native {
  padding: 0 0 !important;
}
.q-field--outlined.q-field--rounded .q-field__control {
  border-radius: 10px !important;
}
.q-field--outlined .q-field__control:before {
  border: 1px solid #8b8b8b !important;
}
.phone-confirm-input {
  .q-field__native,
  .q-field__input {
    text-align: center;
  }
}
.q-field__control {
  width: 116% !important;
  max-width: 320px !important;
}
.q-checkbox__bg {
  width: 40% !important;
  height: 40% !important;
}
.q-field--with-bottom,
.input-margin {
  padding-bottom: 10px !important;
}
</style>
<style lang="scss">
.steps-bg {
  background: #fafafa;
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.error {
  margin-bottom: 10px;
}
.wrapper-lower {
  display: flex;
  flex-direction: column;
}
.docs-wrapper {
  margin-left: -10px;
  margin-bottom: 20px;
}
.q-field__messages {
  position: absolute !important;
}
.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.col {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.input-wrap {
  flex-direction: column;
}
.docs-wrapper {
  display: flex;
  align-items: center;
}
.docs-link {
  color: #000;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}
.required-star {
  font-weight: bold;
}
.q-stepper__title {
  margin-left: 5px;
}
.q-field__native {
  padding-bottom: 0 !important;
}

.inputs-wrapper {
  display: flex;
  justify-content: space-between;
  width: 93%;
  position: relative;
}
.wrapper-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.change-number {
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 25px;
  color: #31bb38;
  text-decoration: underline;
  text-underline-offset: 3px;
}
.change-number:hover {
  text-decoration: none;
  color: #26912b;
}

.step-wrapper {
  display: flex;
  width: 90%;
  justify-content: space-evenly;
  position: relative;
}
.step-wrapper-percent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.-bg {
  background: #35c12c;
  border-radius: 50%;
  padding: 15px 30px;
  font-size: 28px;
  color: white;
}
.second-step-bg,
.third-step-bg {
  background: #d2d2d2;
  border-radius: 50%;
  padding: 15px 30px;
  font-size: 28px;
  color: white;
}

.step-title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-top: 15px;
}
.step-title-1 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}
.step-title-2 {
  font-weight: 500;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.54);
}

.confirm-button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.data-text-lower {
  padding: 8px 85px 8px 15px;
  background: #fafafa;
  position: relative;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  right: -21%;
}
.data-text-top {
  display: none;
  background: #fafafa;
  margin-top: 15px;
  position: relative;
}
.data-sum,
.data-period {
  display: flex;
  align-items: center;
  margin-bottom: -5px;
}

.data-title {
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}
.data-subtitle {
  font-size: 12px;
  background-image: linear-gradient(
    90deg,
    rgba(17, 147, 167, 1) 0%,
    rgba(49, 187, 56, 1) 100%
  );
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  text-decoration: underline;
  text-decoration-color: #35c12c;
  text-underline-offset: 4px;
  margin-bottom: 8px;
  margin-left: 15px;
  font-style: normal;
  font-weight: 600;
}
.custom-ml {
  margin-left: 23px;
}
.percentage-1,
.percentage-2,
.percentage-3 {
  font-size: 12px;
  text-align: center;
  padding-top: 5px;
  font-style: normal;
  font-weight: 500;
}
.confirm-title {
  font-size: 16px;
  margin-bottom: 25px;
  font-weight: 600;
  margin-top: 25px;
}

.confirm-title-second {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 600;
  margin-top: 25px;
}
.confirm-subtitle {
  font-size: 12px;
}
.phone-style {
  font-size: 18px;
  font-weight: 600;
}
.q-field__inner {
  width: 277px !important;
}
.phone-confirm-input {
  .q-field__inner {
    width: 100% !important;
  }
  .code-input {
    .q-field__inner {
      display: flex;
      align-items: center;
      flex-direction: column;
      .q-field__control {
        width: 90% !important;
      }
    }
  }
}

.user .user__inner {
  margin-top: 70px;
}
.profile-wrapper {
  border-radius: 20px;
  background: #fff;
  padding: 20px 40px 5px 40px;
  max-width: 773px;
  width: 100%;
  margin-bottom: 25px;
}
.steps-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
}
.steps-wrapper:first-child {
  align-items: center;
  margin-bottom: -10px;
}
.steps-wrapper.step {
  color: #000;

  font-family: "Jost";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.steps-wrapper:not(:first-child) {
  margin-top: 20px;
}
.change-profile-img {
  display: block;
}
.change-profile-img-mob {
  display: none;
}
.change-profile {
  display: flex;
  margin-top: 13px;
  margin-bottom: 30px;
  align-items: center;
  padding: 12px 18px;
  background: linear-gradient(
    180deg,
    rgba(245, 245, 245, 1) 0%,
    rgba(224, 224, 224, 1) 100%
  );
  border-radius: 35px;
  width: 100%;
  justify-content: space-between;
}
.change-profile-wrapper {
  width: 24% !important;
}
.change-profile-txt {
  color: #898a8f;

  font-family: "Jost";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 111.9%;
}
.q-field--auto-height.q-field--labeled .q-field__control-container {
  padding-top: 20px !important;
}
.material-icons {
  display: none !important;
}
.input-margin.q-field--labeled .q-field__native {
  margin-top: 20px !important;
}

.q-card__section {
  font-size: 16px !important;
}
.q-card__section--vert {
  padding: 8px 35px;
}
.profile-title {
  font-size: 14px;
  margin-bottom: 50px;
}
//media
@media (max-width: 992px) {
  .inputs-wrapper {
    width: 100%;
  }
  .q-field__control {
    width: 100% !important;
  }
}

@media (max-width: 768px) {
  .resend-btn{
    margin: 0 auto;
  }
  .change-profile-wrapper {
    width: 14% !important;
}
  .send-text{
    text-align: center;
  }
  .change-profile-txt {
    display: none;
  }
  .change-profile-img {
    display: none;
  }
  .change-profile-img-mob {
    display: block;
  }
  .phone-confirm-input {
    .q-field__inner {
      width: 100% !important;
    }
  }

  .q-field__control {
    max-width: 100% !important;
  }
  .confirm-button-wrapper {
    flex-direction: column;
  }
  .data-text {
    position: unset;
    border-radius: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .data-text-lower {
    display: none;
  }
  .data-title {
    font-size: 12px;
  }
  .data-subtitle {
    font-size: 14px;
  }
  .loan-subtitle {
    text-align: center;
  }
  .step-title {
    justify-content: center;
  }
  .inputs-wrapper {
    align-items: normal;
    flex-direction: column;
  }
  .change-profile-txt {
    font-size: 12px !important;
  }
  .steps-wrapper.step {
    font-size: 12px !important;
  }
  .resend-btn {
    
    width: 35%;
  }
}
@media (max-width: 500px) {
  img {
  }
}
@media (max-width: 430px) {
  .change-profile-wrapper {
    width: 18% !important;
  }
  .q-stepper__header {
    flex-direction: column;
    align-items: center !important;
  }

  .required-star {
    margin-top: 5px;
  }
  .confirm-button {
    display: flex;
    justify-content: center;
  }
  .profile-wrapper {
    padding: 20px 15px !important;
  }
  .q-card__section {
    font-size: 12px !important;
  }
  .q-card__section--vert {
    padding: 5px 5px !important;
  }
}
@media (max-width: 398px) {
  .text-h6 {
    font-size: 18px !important;
  }

  .consent-text {
    font-size: 14px !important;
  }
  #resendDialog{
    padding: 30px 30px !important;
  }
  .close-btn{
    width: 80% !important; 
  }
}
.q-item__section--main {
  padding-left: 10px;
}
.confirm-retries-text {
  margin-top: -20px;
}
.q-field__after,
.q-field__append {
  padding-left: 0px !important;
}
.phone-confirm-input {
  .q-field--with-bottom {
    padding-bottom: 0 !important;
    margin-bottom: -20px !important;
  }
}
.change-number-btn {
  margin-top: -20px;
}
.text-green {
  color: #31bb38 !important;
}

.q-card {
  overflow: unset !important;
}
.q-card__section {
  text-transform: none !important;
}
@media (max-width: 480px) {
  .profile-title {
    margin-bottom: 30px;
  }
  .q-card__section.ok-subtitle {
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  .profile-title {
    text-align: center;
  }
  .change-profile-wrapper {
        width: 21% !important;
    }
}
.q-field--error .q-field__inner {
  margin-bottom: 11px;

  transition: all 0.2s ease-in-out;
}
.q-field__bottom--animated {
  bottom: 5px !important;
}
.q-card {
  padding: 30px 10px 10px 10px !important;
  border-radius: 10px !important;
  background: #fff !important;
}
.change-number-bg {
  border-radius: 10px;
  background: #fafafa;
  padding: 25px 10px;
}

.change-number-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.change-number-wrapper.q-field__inner {
  width: 100% !important;
}
.change-number-wrapper {
  .q-field__control {
    max-width: 100% !important;
  }
  .q-field__inner {
    width: 200px !important;
  }
  .q-field__native {
    text-align: center;
  }
}
.change-number-title {
  margin-bottom: 20px;
  color: #000;

  text-align: center;
  font-family: Jost;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.change-number-icon {
  position: absolute;
  left: 44.5%;
  top: 22.25%;
}
.change-profile-wrapper {
  width: 38%;
}
.close-btn__wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.resend-dialog__text {
  font-family: jost;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #000000;
  margin-top: 15px;
}
.resend-dialog__title{
  color: #000;
font-family: Jost;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 120%
}
@media (max-width: 1496px) {
  .change-number-icon {
    top: 25.25%;
    left: 44%;
  }
}
@media (max-width: 992px) {
  .change-number-icon {
    left: 40.5%;
    top: 21.6%;
  }
}
@media (max-width: 768px) {
  .change-number-icon {
    left: 91.5%;
    top: 11.5%;
  }
  .confirm-code-btn {
    width: 55%;
  }
}
@media (max-width: 480px) {
  .change-number-icon {
    left: 87.5%;
    top: 11.8%;
  }
}
@media (max-width: 431px) {
  .change-profile-wrapper {
    width: 18% !important;
  }
  .change-number-icon {
    left: 88.5%;
    top: 11.8%;
  }
  .close-btn__wrapper{
    width: 60%;
  }

  dialog{
    padding: 20px 15px;
  }
}
.gradient-text {
  background: linear-gradient(90deg, #1193a7 0%, #31bb38 100%);

  font-weight: 600;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #1193a7;
}
.close-btn {
  border-radius: 5px;
  background: linear-gradient(90deg, #1193a7 0%, #31bb38 100%);
  padding: 8px 30px;
  color: white;
  font-family: Jost;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: all 0.3s ease-in-out;
}
.dialog-change-number-text {
  color: #000;

  font-family: Jost;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.loan-data .q-field--labeled .q-field__native {
  margin-top: 15px;
}
.loan-data-text {
  color: #000;

  font-family: Jost;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin-top: -8px;
}
.resend-dialog__wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.close-btn:hover {
  color: #fff;
  text-decoration: none;
  opacity: 0.85;
}
.input-wrap .q-btn{
  width: 100%;
  padding: 4px 0px !important;
}
.q-btn label{
  width: 100%;
}
.resend-btn__margin {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>

<template>
  <div class="container">
    <div class="user__content profile">
      <Loader v-if="loading"></Loader>
      <div v-if="!loading" class="profile__container user__inner">
        <div class="profile-title">
          Заполните заявку прямо сейчас и мы сможем подобрать заем на Ваших
          условиях!
          <br /><br />Обязательно указывайте достоверные данные, чем больше
          данных вы заполните, тем больше компаний смогут одобрить Вашу заявку.
          <br /><br />
        </div>

        <dialog id="dialog">
          <div style="text-align: center" class="dialog-change-number-text">
            Новый номер успешно сохранен <br />
            <br />
            <button
              class="close-btn"
              onclick="window.dialog.close();"
              aria-label="close"
            >
              ОК
            </button>
          </div>
        </dialog>
        <div class="profile-wrapper">
          <div class="inputs-wrapper loan-data">
            <div class="input-wrap">
              <q-input
                id="anketa-amount"
                color="green"
                rounded
                outlined
                type="number"
                :rules="amountValidate"
                v-model="amount"
                label="Сумма (руб.)"
                placeholder="100 000"
                ref="inputAmount"
              />
            </div>
            <div class="input-wrap">
              <q-input
                id="anketa-period"
                color="green"
                rounded
                outlined
                type="number"
                :rules="periodValidate"
                v-model="period"
                label="Срок (дн.)"
                placeholder="365"
                ref="inputPeriod"
              />
            </div>
          </div>
        </div>
        <div class="profile-wrapper">
          <div class="steps-wrapper">
            <div class="step">
              1. Контактные данные
            </div>
            <div class="change-profile-wrapper">
              
              <button @click="scrollTo()" class="change-profile">
                <div class="change-profile-img">
                  <img src="img/change-profile.svg" alt="" />
                </div>
                <div class="change-profile-img-mob">
                  <img src="img/change-profile-mob.svg" alt="" />
                </div>
                <div class="change-profile-txt">Редактировать</div>
              </button>
            
            </div>
          </div>
          <div class="inputs-wrapper">
            <div class="input-wrap ">
              <q-btn :ripple="false" disable no-caps>
                <q-input
                  :rules="phoneValidate"
                  rounded
                  outlined
                  ref="inputPhone"
                  placeholder="+7 (901) 234-5678"
                  v-model="phone"
                  label="Телефон"
                  class="input-margin"
                  mask="+7 (###) ###-####"
                  color="green"
                  disable
                ></q-input>
                <q-tooltip
                  v-if="!isEditable"
                  anchor="top middle"
                  self="bottom middle"
                  :offset="[10, 10]"
                  transition-show="scale"
                  transition-hide="scale"
                >
                  Сменить номер можно после {{ nextsend }}
                </q-tooltip>
              </q-btn>
              <button
                v-if="isEditable"
                class="change-number-icon"
                @click="changeNumber = !changeNumber"
              >
                <img src="img/change-number.svg" alt="" />
              </button>
            </div>
            <div class="input-wrap" id="step2">
              <q-select
                
                :rules="emailValidate"
                :model-value="email"
                use-input
                hide-selected
                @input-value="(val) => (email = val)"
                fill-input
                rounded
                class="input-req"
                ref="inputEmail"
                aria-expanded="true"
                outlined
                label="Email"
                :options="emailSuggestOptions"
                @filter="fetchEmailSuggest"
                new-value-mode="add"
                placeholder="example@mail.ru"
                behavior="menu"
                color="green"
                :disable="!editable"
              >
              </q-select>
            </div>
          </div>
          <div class="change-number-bg" v-if="changeNumber">
            <div class="change-number-wrapper">
              <div class="change-number-title">
                Введите новый номер телефона
              </div>
              <q-input
                v-model="changingPhone"
                :rules="phoneValidate"
                ref="changingPhoneInput"
                rounded
                outlined
                placeholder="+7 (901) 234-5678"
                mask="+7 (###) ###-####"
                class="d-flex flex-column align-items-center"
                color="green"
              />
              <button
                class="resend-btn"
                :disabled="disabledPhone"
                @click="changePhoneHandler"
              >
                <div class="">Получить код</div>
              </button>

              <div
                style="margin-bottom: 10px;"
                v-if="timeDifference > 0 && retry < 3"
              >
                Получить новый код можно через {{ timerOutput }}
                <div style="text-align: center;">
                  Осталось попыток: {{ 3 - retry }}
                </div>
              </div>

              <q-input
                v-if="showCode"
                v-model="code"
                autocomplete="off"
                style=""
                rounded
                outlined
                mask="#####"
                placeholder="_ _ _ _ _"
                color="green"
              />
              <div
                style="color: #C10015; font-size: 12px;"
                v-if="error !== null"
              >
                {{ error }}
              </div>
              <button
                v-if="showCode"
                @click="changePhoneConfirmHandler"
                class="resend-btn"
                :disabled="disabledCode"
                style="margin-top: 10px;"
              >
                Сохранить
              </button>
            </div>
          </div>
          <div class="steps-wrapper">
            <div class="step">
              2. Личные данные
            </div>
          </div>
          <div class="inputs-wrapper ">
            <div class="input-wrap">
              <q-select
                color="green"
                :rules="lastnameValidate"
                v-model="lastname"
                use-input
                rounded
                outlined
                hide-selected
                @input-value="(val) => (lastname = val)"
                fill-input
                input-debounce="0"
                label="Фамилия"
                placeholder="Иванов"
                class="input-req"
                :options="lastnameSuggestOptions"
                @filter="fetchLastname"
                ref="inputLastname"
                new-value-mode="add"
                behavior="menu"
                :disable="!editable"
              >
              </q-select>
            </div>
            <div class="input-wrap">
              <q-select
                color="green"
                :rules="firstnameValidate"
                v-model="firstname"
                use-input
                rounded
                outlined
                hide-selected
                fill-input
                class="input-req"
                @input-value="(val) => (firstname = val)"
                input-debounce="0"
                label="Имя"
                :options="firstnameSuggestOptions"
                @filter="fetchFirstname"
                new-value-mode="add"
                placeholder="Иван"
                behavior="menu"
                ref="inputFirstname"
                :disable="!editable"
              >
              </q-select>
            </div>
          </div>
          <div class="inputs-wrapper ">
            <div class="input-wrap">
              <q-select
                color="green"
                :rules="secondnameValidate"
                v-model="secondname"
                use-input
                rounded
                outlined
                hide-selected
                class="input-req"
                fill-input
                @input-value="(val) => (secondname = val)"
                input-debounce="0"
                label="Отчество"
                :options="secondnameSuggestOptions"
                @filter="fetchSecondname"
                new-value-mode="add"
                placeholder="Иванович"
                behavior="menu"
                ref="inputSecondname"
                :disable="!editable"
              >
              </q-select>
            </div>
            <div class="input-wrap">
              <q-input
                color="green"
                :rules="dobValidate"
                rounded
                outlined
                class="input-margin"
                v-model="dob"
                label="Дата рождения"
                mask="##.##.####"
                placeholder="25.01.1995"
                ref="inputDob"
                :disable="!editable"
              />
            </div>
          </div>
          <div class="inputs-wrapper">
            <div class="input-wrap">
              <q-select
                color="green"
                :rules="regionValidate"
                rounded
                outlined
                lazy-rules
                v-model="regionTmp"
                class="input-req"
                use-input
                hide-selected
                fill-input
                @input-value="(val) => (region = val)"
                input-debounce="0"
                label="Регион"
                :options="regionSuggestOptions"
                @filter="fetchRegionSuggest"
                placeholder="Красноярский край"
                behavior="menu"
                ref="inputRegion"
                :disable="!editable"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey"> Введите </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
            <div class="input-wrap">
              <q-select
                color="green"
                :rules="cityValidate"
                rounded
                outlined
                lazy-rules
                v-model="cityTmp"
                class="input-req"
                use-input
                hide-selected
                fill-input
                @input-value="(val) => (city = val)"
                input-debounce="0"
                label="Город"
                :options="citySuggestOptions"
                @filter="fetchCitySuggest"
                placeholder="Красноярск"
                behavior="menu"
                ref="inputCity"
                :disable="!editable"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey"> Введите </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
          </div>
          <button :disabled="!editable" @click="resendFake" class="resend-btn">
            Сохранить
          </button>
          <div class="steps-wrapper">
            <div class="step">
              3. Паспортные данные
            </div>
          </div>
          <div class="inputs-wrapper">
            <div class="input-wrap">
              <q-input
                color="green"
                rounded
                outlined
                id="anketa-series"
                v-model="series"
                :rules="seriesValidate"
                label="Серия паспорта"
                ref="inputSeries"
                mask="####"
                placeholder="1234"
                class="input-margin"
                :disable="editableDataPassport"
              />
            </div>
            <div class="input-wrap">
              <q-input
                color="green"
                rounded
                outlined
                id="anketa-number"
                v-model="number"
                :rules="numberValidate"
                label="Номер паспорта"
                mask="######"
                ref="inputNumber"
                placeholder="123456"
                class="input-margin"
                :disable="editableDataPassport"
              />
            </div>
          </div>
          <div class="inputs-wrapper">
            <div class="input-wrap">
              <q-input
                color="green"
                rounded
                outlined
                v-model="fmsCode"
                :rules="fmsCodeValidate"
                @focusout="getFmsUnit"
                label="Код подразделения"
                ref="inputFmsCode"
                mask="###-###"
                placeholder="123-456"
                class="input-margin"
                :disable="editableDataPassport"
              />
            </div>
            <div class="input-wrap">
              <q-input
                :rules="issueDateValidate"
                color="green"
                rounded
                outlined
                id="anketa-issueDate"
                v-model="issueDate"
                label="Дата выдачи"
                ref="inputIssueDate"
                mask="##.##.####"
                placeholder="25.01.2009"
                class="input-margin"
                :disable="editableDataPassport"
              />
            </div>
          </div>

          <button @click="resend" class="resend-btn resend-btn__margin">
            Отправить заявку
          </button>
          <div class="send-text" v-if="editableData">Ваша заявка отправлена {{ date }}</div>
          <dialog id="resendDialog">
            <div class="resend-dialog__wrapper">
              <div style="text-align: center">
                <div class="resend-dialog__title">Спасибо за заявку!</div>
                <div class="resend-dialog__text">В разделе <span class="gradient-text">Витрина предложений</span> уже доступны займы на выгодных условиях:</div>
              </div>
              <div class="close-btn__wrapper">
                <a class="close-btn">
                <router-link to="/showcase">Перейти</router-link>
                </a>
              </div>
            </div>

            <a @click="closeDialog" aria-label="close" class="x">
              <img src="/img/1close.svg" alt="close" />
            </a>
          </dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "debounce";
import Loader from "./Loader";
import * as Auth from "@/assets/js/auth.js";
import * as Suggest from "@/assets/js/suggest.js";
import * as Validate from "@/assets/js/validators.js";
import { changePhoneConfirm } from "../assets/js/auth";
export default {
  name: "Profile",

  props: {
    setRating: Function,
  },

  components: {
    Loader,
  },

  data: () => ({
    firstnameSuggestOptions: [],
    lastnameSuggestOptions: [],
    secondnameSuggestOptions: [],
    regionSuggestOptions: [],
    citySuggestOptions: [],
    emailSuggestOptions: [],
    regionTmp: null,
    cityTmp: null,
    loading: true,
    resendForm: false,
    editable: false,
    isEditable: true,
    resendDialog: false,
    fmsUnit: null,
    regionKladr: "",
    cityKladr: "",
    error: null,
    amount: null,
    period: null,
    id: "",
    sendBid: null,
    phone: "",
    changingPhone: "",
    email: "",
    firstname: "",
    lastname: "",
    secondname: "",
    dob: "",
    city: "",
    region: "",
    series: "",
    number: "",
    fmsCode: null,
    showCode: false,
    code: null,
    changeNumber: false,
    issueDate: "",
    host: location.hostname.replace("lk.", ""),
    date: "",
    nextsend: "",
    editableData: false,
    editableDataPassport: false,
    notEditable: false,
    notEditablePassport: false,
    editableSend: false,
    showText: false,
    resendText: "",
    amount: 0,
    period: 0,
    creditHistoryId: 0,
    countDownToTime: 0,
    timeDifference: 0,
    timerOutput: null,
    interval: null,
    showTimer: false,
    retry: 0,
    canShowBtn: true,
    creditHistoryItems: [
      { id: 0, value: "Нет кредитной истории" },
      { id: 1, value: "Был займ, просрочек не было" },
      { id: 2, value: "Был займ, просрочки были" },
      { id: 3, value: "Открытый займ и просрочки" },
      { id: 4, value: "Работают коллекторы" },
    ],
  }),
  watch: {
    timeDifference(val) {
      if (val < 2) {
        clearInterval(this.interval);
      }
    },
    regionTmp: function(val) {
      this.region = val?.value;
      this.regionKladr = val?.id;
    },
    cityTmp: function(val) {
      this.city = val?.value;
      this.cityKladr = val?.id;
    },
  },

  computed: {
    disabledPhone() {
      this.canShowBtn = this.timeDifference === 0 && this.retry < 3;
      if (!Validate.phone(this.changingPhone) || !this.canShowBtn) {
        return true;
      } else {
        return false;
      }
    },
    disabledCode() {
      if (!Validate.code(this.code)) {
        return true;
      } else {
        return false;
      }
    },
    disabledBtn() {
      if (
        !this.firstname ||
        !this.lastname ||
        !this.email ||
        !this.phone ||
        !this.region ||
        !this.city ||
        !this.dob ||
        !this.secondname
      ) {
        return true;
      } else {
        return false;
      }
    },
    disabledBtnPassport() {
      if (!this.series || !this.number || !this.issueDate || !this.fmsCode) {
        return true;
      } else {
        return false;
      }
    },
    phoneValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите телефон",
        (val) => Validate.phone(val) || "Введите полностью",
      ];
    },
    emailValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите эл. почту",
        (val) => Validate.email(val) || "Введите полностью",
      ];
    },
    lastnameValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите фамилию",
        (val) => Validate.textOnly(val) || "Введите полностью",
      ];
    },
    firstnameValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите имя",
        (val) => Validate.textOnly(val) || "Введите полностью",
      ];
    },
    secondnameValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите отчество",
        (val) => Validate.textOnly(val) || "Введите полностью",
      ];
    },
    dobValidate() {
      return [
        (val) => (val) =>
          (val !== null && val !== "") || "Введите дату рождения",
        (val) => Validate.validate18Years(val) || "Введите верную дату",
      ];
    },
    cityValidate() {
      return [
        (val) =>
          (val?.value !== null && val?.value !== "") ||
          "Введите город проживания",
        (val) => Validate.noempty(val?.value) || "Введите полностью",
      ];
    },
    regionValidate() {
      return [
        (val) =>
          (val?.value !== null && val?.value !== "") ||
          "Введите регион проживания",
        (val) => Validate.noempty(val?.value) || "Введите полностью",
      ];
    },
    seriesValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите серию паспорта",
        (val) => Validate.series(val) || "Введите полностью",
      ];
    },
    numberValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите номер паспорта",
        (val) => Validate.number(val) || "Введите полностью",
      ];
    },
    fmsCodeValidate() {
      return [
        (val) =>
          (val !== null && val !== "") || "Введите код подразделения паспорта",
        (val) => Validate.fmsCode(val) || "Введите полностью",
        (val) => !!this.fmsUnit || "Введите корректно",
      ];
    },
    issueDateValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите дату выдачи паспорта",
        (val) => Validate.date(val) || "Введите верную дату",
      ];
    },
    amountValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите сумму займа",
        (val) => Validate.noempty(val) || "Введите полностью",
      ];
    },
    periodValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите срок займа",
        (val) => Validate.noempty(val) || "Введите полностью",
      ];
    },
  },

  methods: {
    scrollTo() {
      this.editable = !this.editable;
      document.getElementById("step2").scrollIntoView();
    },
    startTimer() {
      this.canShowBtn = false;

      this.countDownToTime = new Date().getTime() + 10000;
      this.interval = setInterval(() => {
        const timeNow = new Date().getTime();
        this.timeDifference = this.countDownToTime - timeNow;
        if (this.timeDifference < 0) {
          this.timeDifference = 0;
        }
        const millisecondsInOneSecond = 1000;
        const millisecondsInOneMinute = millisecondsInOneSecond * 60;
        const millisecondsInOneHour = millisecondsInOneMinute * 60;
        const millisecondsInOneDay = millisecondsInOneHour * 24;
        const differenceInDays = this.timeDifference / millisecondsInOneDay;
        const remainderDifferenceInHours =
          (this.timeDifference % millisecondsInOneDay) / millisecondsInOneHour;
        const remainderDifferenceInMinutes =
          (this.timeDifference % millisecondsInOneHour) /
          millisecondsInOneMinute;
        const remainderDifferenceInSeconds =
          (this.timeDifference % millisecondsInOneMinute) /
          millisecondsInOneSecond;
        const remainingDays = Math.floor(differenceInDays);
        const remainingHours = Math.floor(remainderDifferenceInHours);
        const remainingMinutes = Math.floor(remainderDifferenceInMinutes);
        const remainingSeconds = Math.floor(remainderDifferenceInSeconds);
        this.timerOutput =
          (remainingMinutes < 10 ? "0" + remainingMinutes : remainingMinutes) +
          ":" +
          (remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds);
      }, 1);
    },

    changePhoneHandler() {
      const isValidPhone = this.$refs.changingPhoneInput.validate();
      if (!isValidPhone) {
        return;
      }
      if (this.changingPhone !== this.phone && this.changingPhone !== "") {
        const resp = Auth.changePhoneRequest(this.changingPhone, this.host);
        if (resp.success == false) {
          this.error = resp.error;
        }
        this.showCode = true;
        this.showTimer = true;
        this.startTimer();
        this.retry++;
      }
    },
    async changePhoneConfirmHandler() {
      Auth.load("/client/profile")
      .then((data) => {
        this.loading = false;
       
        this.date = data.bid.date;
        

        this.setRating(data.rating);
      })
      const resp = await Auth.changePhoneConfirm(this.code);
      if (resp.success == false) {
        this.error = resp.error;
      }
      if (resp.success == true) {
        this.phone = this.changingPhone;
        this.showCode = false;

        this.changeNumber = false;
        window.dialog.showModal();
        this.getProfile();
      }
    },

    fetchLastname: debounce(function(search, update) {
      if (!search.length) {
        () => (this.lastnameSuggestOptions = [search]);
        return;
      }
      this.fetchFioSuggest(search, update, "surname");
    }, 500),

    fetchFirstname: debounce(function(search, update) {
      if (!search.length) {
        () => (this.firstnameSuggestOptions = [search]);
        return;
      }
      this.fetchFioSuggest(search, update, "name");
    }, 500),

    fetchSecondname: debounce(function(search, update) {
      if (!search.length) {
        () => (this.secondnameSuggestOptions = [search]);
        return;
      }
      this.fetchFioSuggest(search, update, "patronymic");
    }, 500),

    fetchFioSuggest(search, update, type) {
      Suggest.suggestFio(search, type)
        .then((data) => {
          update(() => {
            if (type === "name") {
              this.firstnameSuggestOptions = data.map((el) => el.text);
            }
            if (type === "surname") {
              this.lastnameSuggestOptions = data.map((el) => el.text);
            }
            if (type === "patronymic") {
              this.secondnameSuggestOptions = data.map((el) => el.text);
            }
          });
        })
        .catch(() => {
          update(() => {
            if (type === "name") {
              this.firstnameSuggestOptions = [search];
            }
            if (type === "surname") {
              this.lastnameSuggestOptions = [search];
            }
            if (type === "patronymic") {
              this.secondnameSuggestOptions = [search];
            }
          });
        });
    },
    fetchEmailSuggest: debounce(function(search, update) {
      if (!search.length) {
        () => (this.emailSuggestOptions = []);
        return;
      }
      Suggest.suggestEmail(search)
        .then((data) => {
          update(() => {
            if (data.length)
              this.emailSuggestOptions = data.map((el) => el.text);
            else this.emailSuggestOptions = [search];
          });
        })
        .catch(() => {
          update(() => {
            this.emailSuggestOptions = [search];
          });
        });
    }, 500),

    fetchRegionSuggest: debounce(function(search, update) {
      if (search.length < 2) {
        () => (this.regionSuggestOptions = []);
        return;
      }
      Suggest.suggestRegion(search)
        .then((data) => {
          update(() => {
            if (data.length)
              this.regionSuggestOptions = data.map((el) => ({
                value: el.text,
                label: el.text,
                id: el.id,
              }));
            else this.regionSuggestOptions = [];
          });
        })
        .catch(() => {
          update(() => {
            this.regionSuggestOptions = [];
          });
        });
    }, 500),

    //city
    fetchCitySuggest: debounce(function(search, update) {
      if (search.length < 2) {
        () => (this.citySuggestOptions = []);
        return;
      }
      Suggest.suggestCity(search, this.regionKladr)
        .then((data) => {
          update(() => {
            if (data.length)
              this.citySuggestOptions = data.map((el) => ({
                value: el.text,
                label: el.text,
                id: el.id,
              }));
            else this.citySuggestOptions = [];
          });
        })
        .catch(() => {
          update(() => {
            this.citySuggestOptions = [];
          });
        });
    }, 500),

    getCreditHistoryString(id) {
      var text;
      this.creditHistoryItems.forEach(function(item) {
        if (id === item.id) {
          text = item.value;
        }
      });
      return text;
    },

    numberFormat(num) {
      return String(num)
        .replace(/[^0-9]/g, "")
        .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ");
    },
    getFmsUnit() {
      Suggest.suggestFmsCode(this.fmsCode)
        .then((data) => {
          this.fmsUnit = data;
          this.$refs.inputFmsCode.validate();
          if (!data) {
          }
        })
        .catch((err) => {
          this.fmsCode = null;
          this.$refs.inputFmsCode.validate();
        });
    },

    resendInit(e) {
      e.preventDefault();
      this.resendForm = true;
    },


    resend(e) {
      e.preventDefault();
      this.resendForm = false;
      this.amount = parseInt(this.amount) || 10000;
      this.period = parseInt(this.period) || 30;
      this.creditHistoryId = parseInt(this.creditHistoryId) || 0;
      const isValidPhone = this.$refs.inputPhone.validate();
      const isValidEmail = this.$refs.inputEmail.validate();
      const isValidLastname = this.$refs.inputLastname.validate();
      const isValidFirstname = this.$refs.inputFirstname.validate();
      const isValidSecondname = this.$refs.inputSecondname.validate();
      const isValidDob = this.$refs.inputDob.validate();
      const isValidRegion = this.$refs.inputRegion.validate();
      const isValidCity = this.$refs.inputCity.validate();
      const isValidAmount = this.$refs.inputAmount.validate();
      const isValidPeriod = this.$refs.inputPeriod.validate();

      if (this.series || this.number || this.issueDate || this.fmsCode) {
        const isValidSeries = this.$refs.inputSeries.validate();
        const isValidNumber = this.$refs.inputNumber.validate();
        const isValidFmsCode = this.$refs.inputFmsCode.validate();
        const isValidIssueDate = this.$refs.inputIssueDate.validate();

        if (
          !isValidSeries || !isValidNumber || !isValidFmsCode || !isValidIssueDate){
            return;
          }
      }

      if (
        !isValidAmount ||
        !isValidPeriod ||
        !isValidPhone ||
        !isValidEmail ||
        !isValidLastname ||
        !isValidFirstname ||
        !isValidSecondname ||
        !isValidDob ||
        !isValidRegion ||
        !isValidCity
      ) {
        return;
      }
      Auth.load("/client/bid/create", {
        firstname: this.firstname,
        lastname: this.lastname,
        secondname: this.secondname,
        email: this.email,
        phone: this.phone,
        dob: this.dob,
        city: this.cityTmp.value,
        region: this.regionTmp.value,
        cityKladrId: this.cityTmp.id,
        regionKladrId: this.regionTmp.id,
        passport: {
          series: this.series,
          number: this.number,
          fmsCode: this.fmsCode,
          issueDate: this.issueDate,
          fmsUnit: this.fmsUnit,
          birthPlace: this.cityTmp.value,
        },
        consent: true,
        amount: this.amount,
        period: this.period,
        host: this.host,
      })
        .then(() => {
          const dialog = document.querySelector("#resendDialog");
          dialog.showModal();
          this.getProfile();
          this.editableData = true;
          this.editable = false;
          this.showText = true;
          
        })
        .catch(() => {
          Auth.logout();
          this.$router.replace({ name: "Login" });
        });
    },
    resendFake(e) {
      e.preventDefault();
      this.resendForm = false;
      this.amount = parseInt(this.amount) || 10000;
      this.period = parseInt(this.period) || 30;
      this.creditHistoryId = parseInt(this.creditHistoryId) || 0;
      const isValidPhone = this.$refs.inputPhone.validate();
      const isValidEmail = this.$refs.inputEmail.validate();
      const isValidLastname = this.$refs.inputLastname.validate();
      const isValidFirstname = this.$refs.inputFirstname.validate();
      const isValidSecondname = this.$refs.inputSecondname.validate();
      const isValidDob = this.$refs.inputDob.validate();
      const isValidRegion = this.$refs.inputRegion.validate();
      const isValidCity = this.$refs.inputCity.validate();
      const isValidAmount = this.$refs.inputAmount.validate();
      const isValidPeriod = this.$refs.inputPeriod.validate();

      if (
        !isValidAmount ||
        !isValidPeriod ||
        !isValidPhone ||
        !isValidEmail ||
        !isValidLastname ||
        !isValidFirstname ||
        !isValidSecondname ||
        !isValidDob ||
        !isValidRegion ||
        !isValidCity
      ) {
        return;
      }
      Auth.load("/client/bid/create", {
        firstname: this.firstname,
        lastname: this.lastname,
        secondname: this.secondname,
        email: this.email,
        phone: this.phone,
        dob: this.dob,
        city: this.cityTmp.value,
        region: this.regionTmp.value,
        cityKladrId: this.cityTmp.id,
        regionKladrId: this.regionTmp.id,
        passport: {
          series: this.series,
          number: this.number,
          fmsCode: this.fmsCode,
          issueDate: this.issueDate,
          fmsUnit: this.fmsUnit,
          birthPlace: this.cityTmp.value,
        },
        consent: true,
        amount: this.amount,
        period: this.period,
        host: this.host,
      })
        .then(() => {
          
          this.editable = false;
        })
        .catch(() => {
          Auth.logout();
          this.$router.replace({ name: "Login" });
        });
    },

    resendPassport(e) {
      e.preventDefault();
      this.resendForm = false;
      this.amount = parseInt(this.amount) || 10000;
      this.period = parseInt(this.period) || 30;
      const isValidSeries = this.$refs.inputSeries.validate();
      const isValidNumber = this.$refs.inputNumber.validate();
      const isValidFmsCode = this.$refs.inputFmsCode.validate();
      const isValidIssueDate = this.$refs.inputIssueDate.validate();
      const isValidLastname = this.$refs.inputLastname.validate();
      const isValidFirstname = this.$refs.inputFirstname.validate();
      const isValidSecondname = this.$refs.inputSecondname.validate();
      const isValidDob = this.$refs.inputDob.validate();
      const isValidRegion = this.$refs.inputRegion.validate();
      const isValidCity = this.$refs.inputCity.validate();
      const isValidAmount = this.$refs.inputAmount.validate();
      const isValidPeriod = this.$refs.inputPeriod.validate();
      if (
        !isValidAmount ||
        !isValidPeriod ||
        !isValidSeries ||
        !isValidNumber ||
        !isValidFmsCode ||
        !isValidIssueDate ||
        !isValidLastname ||
        !isValidFirstname ||
        !isValidSecondname ||
        !isValidDob ||
        !isValidRegion ||
        !isValidCity
      ) {
        return;
      }
      Auth.load("/client/bid/create", {
        firstname: this.firstname,
        lastname: this.lastname,
        secondname: this.secondname,
        email: this.email,
        phone: this.phone,
        dob: this.dob,
        city: this.cityTmp.value,
        region: this.regionTmp.value,
        cityKladrId: this.cityTmp.id,
        regionKladrId: this.regionTmp.id,
        passport: {
          series: this.series,
          number: this.number,
          fmsCode: this.fmsCode,
          issueDate: this.issueDate,
          fmsUnit: this.fmsUnit,
          birthPlace: this.cityTmp.value,
        },
        consent: true,
        amount: this.amount,
        period: this.period,
        host: this.host,
      }).then(() => {
        this.editableDataPassport = true;
      });
    },
    closeDialog() {
      const dialog = document.querySelector("#resendDialog");
      dialog.close();
    },
    getProfile() {
      Auth.load("/client/profile")
        .then((data) => {
          this.loading = false;
          localStorage.setItem("profile", JSON.stringify(data.profile));
          this.id = data.id;
          this.phone = data.profile.phone;
          this.email = data.profile.email;
          this.firstname = data.profile.firstname;
          this.lastname = data.profile.lastname;
          this.secondname = data.profile.secondname;
          this.city = data.profile.city;
          this.cityTmp = {
            value: data.profile.city,
            label: data.profile.city,
            id: data.profile.cityKladrId,
          };
          this.regionTmp = {
            value: data.profile.region,
            label: data.profile.region,
            id: data.profile.regionKladrId,
          };
          this.region = data.profile.region;
          this.dob = data.profile.dob;
          this.series = data.profile.series;
          this.number = data.profile.number;
          this.issueDate = data.profile.issueDate;
          this.fmsCode = data.profile.fmsCode;
          this.fmsUnit = data.profile.fmsUnit;
          this.date = data.bid.date;
          this.nextsend = data.bid.nextsend;
          this.amount = data.bid.amount;
          this.period = data.bid.period;
          this.creditHistoryId = data.bid.creditHistory;
          this.isEditable = data.bid.editableSend;
          this.setRating(data.rating);
          this.editableData = data.bid.editableData;
          this.sendBid = data.bid.sendBid;
          this.editableDataPassport = data.bid.editableDataPassport;
        })
        .catch(() => {
          Auth.logout();
          this.$router.replace({ name: "Login" });
        });
    },
  },

  mounted() {
    if (!Auth.getToken()) {
      return this.$router.replace({ name: "Login" });
    }
    this.getProfile();
    window.scrollTo({ top: 0, behavior: "smooth" });
  },
};
</script>
