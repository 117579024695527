<style>

.showcase-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.showcase-title {
  width: 80%;
  color: #000;

font-family: Jost;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.sort-title {
  border-radius: 50px;
  background: #fff;
  padding: 6px 18px;
  cursor: pointer;
}
.sort-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dropdown-arrow,
.name {
  margin-left: 5px;
}
.name {
  color: #3e3d3d;
  font-family: "Jost";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}
.showcase-wrapper {

  display: flex;
  flex-direction: column;
  padding-bottom: 25px;
}

.partner:nth-child(1) {
  animation: none !important;
}
.partner-card {
  align-items: center;
  background: #ffffff;
  border-radius: 10px;
  padding: 35px;
  transition: all 0.3s ease-in-out;
  max-width: 48%;
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
}
.partner-card{
  margin-top: 20px;
}
.user__arrows {
  top: 20px !important;
}
.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.showcase-container {
  display: flex;

  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
}
.partner-wrap {
  width: 100%;
}
.partner-wrap-btn {
  width: 90%;
}

.partner__logo {
  max-width: 98px;
  max-height: 45px;
  margin-bottom: 25px;
}
.partner-wrap:not(:last-child) {
  margin-bottom: 12px;
}
.partner-text {
  font-size: 10px;
}
.partner-data {
  font-size: 10px;
}
.partner-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-size: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 500;
  border-radius: 5px;
  background: linear-gradient(
    90deg,
    rgba(17, 147, 167, 1) 0%,
    rgba(49, 187, 56, 1) 100%
  );
  position: relative;
  z-index: 1;
  transition: 0.4s all ease-in-out;
  cursor: pointer;
}

.partner-btn:hover {
  color: #fff;
  text-decoration: none;
  opacity: 0.85;
}
.partner-btn:visited{
   background: #000;

}
.show-more-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
}
.partner-btn-show-more {
  border-radius: 10px;
  background: #f1f1f1;
  color: #3e3d3d;
  text-align: center;
  font-family: "Jost";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;

  padding: 15px 15px;
  transition: all .3s ease-in-out;
  cursor: pointer;
  border: 1px solid  #f1f1f1;
}
.partner-btn-show-more:hover {
  background: transparent;
  text-decoration: none;

  border: 1px solid  #f1f1f1;
}

.showcase-sort-btn {
  margin-left: 5px;
}
.sort-menu {
  border-radius: 10px;
  background: #f3f3f3;
  padding: 13px 18px;
  max-width: 160px;
  width: 100%;
  position: absolute;
  right: 0;
  top: 45px;
  z-index: 2;
}
.sort-menu-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.sort-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.sort-btn:not(:last-child) {
  margin-bottom: 15px;
}
.sort-txt {
  color: #3e3d3d;
  font-family: "Jost";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-left: 5px;
}

@media (max-width: 400px) {
  .showcase-wrapper {
    margin-top: 0px;
  }
}
@media (max-width: 600px) {
  .showcase-title-txt{
    font-size: 12px;
  }
  .partner-btn-show-more{
    width: 50%;
  }
  .sort-menu{
    padding: 13px 13px;
  }
}
@media (min-width: 0) {
  .container {
    max-width: 347px;
  }
  .partner-card {
    padding: 15px;
  }
}
@media (max-width: 479.98px) {
  .sort-title-wrapper .name{
  display: none;
}
.sort-title-wrapper .dropdown-arrow{
  display: none;
}
.sort-title{
  padding: 6px 8px;
}
.sort-menu{
  max-width: 148px;
}
}
@media (min-width: 479.98px) {
  .container {
    max-width: 490px;
  }
}
@media (min-width: 767.98px) {
  .partner-btn-show-more {
    width: 18%;
  }
  .container {
    max-width: 720px;
  }
  .partner-card {
    max-width: 21%;
  }
  .showcase-wrapper {
    padding-right: 0px;
  }
}
@media (min-width: 991.98px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1495.98px) {
  .container {
    max-width: 1110px;
  }
  .partner-wrap-btn {
    width: 100%;
  }
  .partner-btn {
    width: 75%;
  }
  .partner-card {
    padding: 35px;
  }
  .partner-card {
    align-items: flex-start;
  }
}

.user .lk-title {
  text-align: center;
  font-size: 20px;
}
.mt-20 {
  margin-top: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}

input[type="radio"] {
  --s: 1em; /* control the size */
  --c: #35c12c; /* the active color */

  height: var(--s);
  aspect-ratio: 1;
  border: calc(var(--s) / 8) solid #939393;
  padding: calc(var(--s) / 8);
  background: radial-gradient(farthest-side, var(--c) 94%, #0000) 50%/0 0
    no-repeat content-box;
  border-radius: 50%;
  outline-offset: calc(var(--s) / 10);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  font-size: inherit;
  transition: 0.3s;
  cursor: pointer;
}
input[type="radio"]:checked {
  border-color: var(--c);
  background-size: 100% 100%;
}
label {
  cursor: pointer;
}
.visited{
  background: #f1f1f1;
  color: #000;
  border-color: #f1f1f1;
  opacity: .6;
  
}
</style>

<template>
  <div class="user__content showcase">
    <Loader v-if="loading"></Loader>

    <div class="showcase-wrapper container">
      <div class="showcase-title-wrapper lk-title">
        <div class="showcase-title">
          Мы подобрали для вас актуальные займы на <span class="gradient-text">{{ date }}</span>.
        </div>
        <div @click="showcaseSort" id="sorting-btn" class="sort-title">
          <div class="sort-title-wrapper">
            <div class="icon">
              <img src="/img/sort.svg" alt="" />
            </div>
            <div class="name">
              Сортировать
            </div>
            <div
              :class="{ toggled: showcaseSortActive }"
              class="dropdown-arrow"
            >
              <img src="/img/down-arrow.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="sort-menu" id="sorting" v-show="showcaseSortActive">
          <div class="sort-menu-wrapper">
       
            <div @click="showcaseSortAmountUp" class="sort-btn">
              <div class="sort-img">
                <img src="/img/arrow-up.svg" alt="" />
              </div>
              <div class="sort-txt">
                По сумме (возр.)
              </div>
            </div>
            
            <div @click="showcaseSortAmountDown" class="sort-btn">
              <div class="sort-img">
                <img src="/img/arrow-down.svg" alt="" />
              </div>
              <div class="sort-txt">
                По сумме (убыв.)
              </div>
            </div>
            <div @click="showcaseSortPeriodUp" class="sort-btn">
              <div class="sort-img">
                <img src="/img/arrow-up.svg" alt="" />
              </div>
              <div class="sort-txt">
                По сроку (возр.)
              </div>
            </div>
            <div @click="showcaseSortPeriodDown" class="sort-btn">
              <div class="sort-img">
                <img src="/img/arrow-down.svg" alt="" />
              </div>
              <div class="sort-txt">
                По сроку (убыв.)
              </div>
            </div>
            <div @click="showcaseSortRatingDown" class="sort-btn">
              <div class="sort-img">
                <img src="/img/arrow-up.svg" alt="" />
              </div>
              <div class="sort-txt">
                По рейтингу (возр.)
              </div>
            </div>
       

           
            <div @click="showcaseSortRatingUp" class="sort-btn">
              <div class="sort-img">
                <img src="/img/arrow-down.svg" alt="" />
              </div>
              <div class="sort-txt">
                По рейтингу (убыв.)
              </div>
            </div>
            
          </div>
        </div>
      </div>

      
      <div v-if="!loading" class="showcase-container">
        <div v-for="showcase, index in showcaseItemsParts" class="partner-card">
          <div class="partner__logo">
            <img :src="showcase.logo" :alt="showcase.name" />
          </div>
          <div class="partner-wrap">
            <div class="wrapper">
              <span class="partner-text">Сумма:</span
              ><span class="partner-data"
                >до {{ numberFormat(showcase.amount) }} р.</span
              >
            </div>
          </div>
          <div class="partner-wrap">
            <div class="wrapper">
              <span class="partner-text">Срок:</span
              ><span class="partner-data">до {{ showcase.period }} дней</span>
            </div>
          </div>
          <div class="partner-wrap">
            <div class="wrapper">
              <span class="partner-text">Без процентов:</span
              ><span v-if="showcase.without_percent" class="partner-data">Да</span>
              <span v-if="!showcase.without_percent" class="partner-data">Нет</span>
            </div>
          </div>
          <div class="partner-wrap">
            <div class="wrapper">
              <span class="partner-text">Рейтинг</span>
              <star-rating :max="5" :current="showcase.rating"></star-rating>
            </div>
          </div>
          <div class="partner-wrap-btn">
            <div id="partnerBtn" class="partner-btn" :class="{ visited: visitedLinks.includes(index) }" @click="goToLink(showcase, index)">
              <span v-if="!visitedLinks.includes(index)">{{ text }}</span>
              <span v-if="visitedLinks.includes(index)" class="visited-text">{{ textVisited }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="show-more-wrapper">
        <div
          v-if="showcaseCounter < showcaseItems.length"
          @click="showcaseCounter += 8"
          class="partner-btn-show-more mb-20 mt-20"
        >
          Показать еще
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "./Loader";
import * as Auth from "@/assets/js/auth.js";
import StarRating from "@/components/StarRating";
export default {
  name: "Showcase",
  components: { Loader, StarRating },

  data: () => ({
    loading: true,
    date: null,
    firstname: null,
    showcaseItems: [],
    showcaseCounter: 8,
    showcaseSortActive: false,
    value: null,
    visitedLinks: [],
    text: 'Получить заем',
    textVisited: 'Просмотрено',
    textPercent: ''
  }),

  methods: {
    numberFormat(num) {
      return String(num)
        .replace(/[^0-9]/g, "")
        .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ");
    },
    showcaseSort(e) {
      e.stopPropagation();

      this.showcaseSortActive = !this.showcaseSortActive;

    },
    goToLink(showcase, index) {
      this.visitedLinks.push(index);
     
      window.open(showcase.link, "_blank");
      
    },
    
    
  },
  computed: {
    showcaseItemsParts() {
      return this.showcaseItems.slice(0, this.showcaseCounter);
    },
    showcaseSortAmountUp() {
      this.showcaseItems.sort((a, b) => a.amount - b.amount);
    },
    showcaseSortAmountDown() {
      this.showcaseItems.sort((a, b) => b.amount - a.amount);
    },
    showcaseSortPeriodUp() {
      this.showcaseItems.sort((a, b) => a.period - b.period);
    },
    showcaseSortPeriodDown() {
      this.showcaseItems.sort((a, b) => b.period - a.period);
    },
    showcaseSortRatingUp() {
      this.showcaseItems.sort((a, b) => b.rating - a.rating);
    },
    showcaseSortRatingDown() {
      this.showcaseItems.sort((a, b) => a.rating - b.rating);
    },
 
  },

  mounted() {
    if (!Auth.getToken()) {
      return this.$router.replace({ name: "Login" });
    }

    Auth.load("/client/showcase?category=МФО")
      .then((data) => {
        this.loading = false;
        this.date = data.date;
        this.firstname = data.firstname;
        this.showcaseItems = data.showcase;
      })
      .catch(() => {
        Auth.logout();
        this.$router.replace({ name: "Login" });
      });
      document.addEventListener("mousedown", (e) => {
      var container = document.getElementById("sorting");
      var containerBtn = document.getElementById("sorting-btn");
      if (!container?.contains(e.target) && !containerBtn?.contains(e.target) && e.target.id !== "sorting-btn") {
        this.showcaseSortActive = false;
      }
   
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  },
};
</script>
