<style scoped>
.input-wrap:nth-child(2) {
  margin-bottom: 10px !important;
}
.profile-wrapper {
  border-radius: 20px;
  background: #fff;
  padding: 20px 40px;
  max-width: 773px;
  width: 100%;
}
.resend-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-size: 14px;
  padding: 10px 15px;
  font-weight: 500;
  border-radius: 5px;
  background: linear-gradient(
    90deg,
    rgba(17, 147, 167, 1) 0%,
    rgba(49, 187, 56, 1) 100%
  );
  position: relative;
  z-index: 1;
  transition: 0.4s all ease-in-out;
  cursor: pointer;
  width: 24%;
  margin-top: 35px;
}
@media (max-width: 768px) {
  .resend-btn {
    margin: 0 auto;
    width: 60%;
    margin-top: 40px;
    margin-bottom: 20px;
  }
}

.bg-green {
  background: linear-gradient(
    90deg,
    rgba(17, 147, 167, 1) 0%,
    rgba(49, 187, 56, 1) 100%
  ) !important;
}
@media (max-width: 500px) {
  .data-text {
    align-items: center;
    padding: 10px 0px;
  }
}
</style>
<style lang="scss">
.resend-btn:hover {
  color: #fff;
  text-decoration: none;
  opacity: 0.85;
}
.q-btn--dense.q-btn--round {
  min-height: 20px !important;
  min-width: 22px !important;
}
.q-card > div:not(.q--avoid-card-border) {
  margin-top: 0px !important;
}

.close-popup {
  left: 98%;
  top: -66%;
  background: #d9d9d9 !important;
  width: 9%;
  position: absolute !important;
  width: 15px !important;
  height: 15px !important;
  padding: 0px !important;
  .q-icon {
    font-size: 14px !important;
  }
  .col {
    padding: 0px !important;
  }
}
.q-virtual-scroll__content {
  border-radius: 8px;
  background: #fafafa;
}
.q-item__label {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 6px;
  padding-bottom: 6px;
}
.q-item {
  padding: 0 !important;
  min-height: 20px !important;
}
.q-item:hover {
  background: #f2f2f2;
}
.q-menu {
  box-shadow: none !important;
}
.q-virtual-scroll__content {
  margin-top: 6px !important;
}
.q-field__label {
  color: #8b8b8b !important;
}
.block {
  text-transform: none !important;
}
.q-btn--rounded {
  border-radius: 10px !important;
  .block {
    text-transform: none !important;
    padding-left: 25px;
    padding-right: 25px;
  }
}
.q-btn {
  padding: 4px 3px !important;
  font-size: 12px !important;
}
.q-field__label {
  font-size: 12px !important;
  top: 15px !important;
}
.q-field--auto-height .q-field__control {
  height: 50px !important;
  min-height: 50px !important;
}
.q-field__control {
  height: 50px !important;
  min-height: 50px !important;
}
.q-field--labeled .q-field__native {
  font-size: 12px !important;
}
.q-field__marginal {
  height: 50px !important;
}
.q-btn:before {
  box-shadow: none !important;
}
#resendDialog {
  padding: 30px 50px;
  border-radius: 10px;
  background: #FFF;
  border: 0;
  margin: 0 auto;
  top: 10%;
  width: 470px;
  animation: fadeIn 1s ease both;
  &::backdrop {
    animation: fadeIn 1s ease both;
    opacity: 0.9;
    background: rgba(71, 71, 71, 0.70);
    z-index: 2;
  }
  .x {
    filter: grayscale(1);
    border: none;
    background: none;
    position: absolute;
    top: 12px;
    right: 12px;
    transition: ease filter, transform 0.3s;
    cursor: pointer;
    transform-origin: center;
    &:hover {
      filter: grayscale(0);
      transform: scale(1.1);
    }
  }
  .dialog__title {
    color: #000;
    font-family: Jost;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
  }
}
@media (max-width: 470px) {
  .input {
    .q-field--outlined .q-field__control {
      padding: 0 18px 0 12px !important;
    }
  }
  .q-item__label {
    font-size: 16px !important;
    padding-left: 10px !important;
  }
  .q-field__append + .q-field__append {
    padding-left: 1px !important;
  }
}
.q-card__actions--horiz {
  justify-content: center !important;
}
.q-field__native {
  padding: 0 0 !important;
}
.q-field--outlined.q-field--rounded .q-field__control {
  border-radius: 10px !important;
}
.q-field--outlined .q-field__control:before {
  border: 1px solid #8b8b8b !important;
}
.phone-confirm-input {
  .q-field__native,
  .q-field__input {
    text-align: center;
  }
}
.q-field__control {
  width: 116% !important;
  max-width: 320px !important;
}
.q-checkbox__bg {
  width: 40% !important;
  height: 40% !important;
}
.q-field--with-bottom,
.input-margin {
  padding-bottom: 10px !important;
}
</style>
<style lang="scss">
.steps-bg {
  background: #fafafa;
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.wrapper-lower {
  display: flex;
  flex-direction: column;
}
.docs-wrapper {
  margin-left: -10px;
  margin-bottom: 20px;
}
.q-field__messages {
  position: absolute !important;
}
.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.col {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.input-wrap {
  flex-direction: column;
}
.docs-wrapper {
  display: flex;
  align-items: center;
}
.docs-link {
  color: #000;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}
.required-star {
  font-weight: bold;
}
.q-stepper__title {
  margin-left: 5px;
}
.q-field__native {
  padding-bottom: 0 !important;
}

.inputs-wrapper {
  display: flex;
  justify-content: space-between;
  width: 93%;
}
.wrapper-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.change-number {
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 25px;
  color: #31bb38;
  text-decoration: underline;
  text-underline-offset: 3px;
}
.change-number:hover {
  text-decoration: none;
  color: #26912b;
}

.step-wrapper {
  display: flex;
  width: 90%;
  justify-content: space-evenly;
  position: relative;
}
.step-wrapper-percent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.step-title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-top: 15px;
}
.step-title-1 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}
.step-title-2 {
  font-weight: 500;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.54);
}

.confirm-button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.data-text-lower {
  padding: 8px 85px 8px 15px;
  background: #fafafa;
  position: relative;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  right: -21%;
}
.data-text-top {
  display: none;
  background: #fafafa;
  margin-top: 15px;
  position: relative;
}
.data-sum,
.data-period {
  display: flex;
  align-items: center;
  margin-bottom: -5px;
}

.data-title {
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}
.data-subtitle {
  font-size: 12px;
  background-image: linear-gradient(
    90deg,
    rgba(17, 147, 167, 1) 0%,
    rgba(49, 187, 56, 1) 100%
  );
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  text-decoration: underline;
  text-decoration-color: #35c12c;
  text-underline-offset: 4px;
  margin-bottom: 8px;
  margin-left: 15px;
  font-style: normal;
  font-weight: 600;
}
.custom-ml {
  margin-left: 23px;
}
.percentage-1,
.percentage-2,
.percentage-3 {
  font-size: 12px;
  text-align: center;
  padding-top: 5px;
  font-style: normal;
  font-weight: 500;
}
.confirm-title {
  font-size: 16px;
  margin-bottom: 25px;
  font-weight: 600;
  margin-top: 25px;
}

.confirm-title-second {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 600;
  margin-top: 25px;
}
.confirm-subtitle {
  font-size: 12px;
}
.phone-style {
  font-size: 18px;
  font-weight: 600;
}
.q-field__inner {
  width: 277px !important;
}
.phone-confirm-input {
  .q-field__inner {
    width: 100% !important;
  }
  .code-input {
    .q-field__inner {
      display: flex;
      align-items: center;
      flex-direction: column;
      .q-field__control {
        width: 90% !important;
      }
    }
  }
}

.user .user__inner {
  margin-top: 70px;
}

.steps-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
}
.steps-wrapper.step {
  color: #000;

  font-family: "Jost";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


.q-field--auto-height.q-field--labeled .q-field__control-container {
  padding-top: 20px !important;
}
.material-icons {
  display: none !important;
}
.input-margin.q-field--labeled .q-field__native {
  margin-top: 20px !important;
}
.input-wrap-property {
  display: flex;
  flex-direction: row;
}
.form-title {
  margin-bottom: 20px;
}
.properties-data {
  display: flex;
  width: 72%;
}
.profile__container .error {
  color: #c10015;
  margin-top: -15px;
  text-align: center;
  font-size: 12px;
  margin-bottom: 0px !important;
  margin-left: 21%;
  margin-top: -6%;
}

//media
@media (max-width: 992px) {
  .inputs-wrapper {
    width: 100%;
  }
  .q-field__control {
    width: 100% !important;
  }
  .properties-data {
    width: 78% !important;
  }
  .profile__container .error {
    margin-left: 31%;
    margin-top: -6%;
  }
}

@media (max-width: 768px) {
 

  .send-text{
    text-align: center;
  }
  .phone-confirm-input {
    .q-field__inner {
      width: 100% !important;
    }
  }

  .q-field__control {
    max-width: 100% !important;
  }
  .confirm-button-wrapper {
    flex-direction: column;
  }
  .data-text {
    position: unset;
    border-radius: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .data-text-lower {
    display: none;
  }
  .data-title {
    font-size: 12px;
  }
  .data-subtitle {
    font-size: 14px;
  }
  .loan-subtitle {
    text-align: center;
  }
  .step-title {
    justify-content: center;
  }
  .inputs-wrapper {
    align-items: normal;
    flex-direction: column;
    margin-bottom: -5px;
  }
  .profile__container .error {
    margin-left: 0%;
    margin-top: -8%;
  }

  .steps-wrapper.step {
    font-size: 12px !important;
  }

  .properties-wrap {
    align-items: center;
  }
  .properties-data {
    display: block !important;
    flex-direction: column;
    margin-top: -10px;
    width: 100% !important;
  }
  .properties-wrap {
    display: flex;
    align-items: center;
  }
  .input-wrap {
    width: 100%;
    margin-bottom: 5px;
  }
}

@media (max-width: 430px) {
  .q-stepper__header {
    flex-direction: column;
    align-items: center !important;
  }

  .required-star {
    margin-top: 5px;
  }
  .confirm-button {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 398px) {
  .text-h6 {
    font-size: 18px !important;
  }

  .consent-text {
    font-size: 14px !important;
  }
  .form-title {
    text-align: center;
  }
}
.q-item__section--main {
  padding-left: 10px;
}
.confirm-retries-text {
  margin-top: -20px;
}
.q-field__after,
.q-field__append {
  padding-left: 0px !important;
}
.phone-confirm-input {
  .q-field--with-bottom {
    padding-bottom: 0 !important;
    margin-bottom: -20px !important;
  }
}
.change-number-btn {
  margin-top: -20px;
}
.text-green {
  color: #31bb38 !important;
}

.q-card {
  overflow: unset !important;
}
.first-step {
  margin-bottom: 10px;
}
@media (max-width: 480px) {
  .input-wrap-property {
    margin-left: -10px !important;
  }
}

.step-text {
  color: #808080;
  font-family: "Jost";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.q-card {
  overflow: unset !important;
}
.q-radio__inner {
  font-size: 26px !important;
  width: 36px !important;
}
.input-wrap-property {
  margin-left: -10px !important;
}
.q-radio__bg path {
  fill: #808080 !important;
}
.step-text {
  color: #808080;
  font-family: "Jost";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.q-radio__label {
  margin-left: -5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #808080 !important;
}
.radio-custom {
  margin-left: 24px !important;
}
.q-tooltip{
  font-size: 10px !important;
}

.profile-title{
  font-size: 14px;
}
.close-btn {
  border-radius: 8px;
  background: linear-gradient(90deg, #1193a7 0%, #31bb38 100%);
  margin-top: 15px;
  padding: 15px 20px;
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  font-family: jost;
  width: 100%;
}
.close-btn__wrapper__bankru {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.input-wrap .q-btn{
  width: 100%;
  padding: 4px 0px;
}
.q-btn label{
  width: 100%;
}
.resend-btn__margin {
  margin-top: 10px;
  margin-bottom: 10px;
}

</style>
<template>
  <div class="container">
    <div class="user__content profile">
      <Loader v-if="loading"></Loader>
      <div v-if="!loading" class="profile__container user__inner">
        <div class="profile-title" v-if="isEditable">
          Отправьте заявку на консультацию по банкротству прямо сейчас и
          получите возможность списать долги со скидкой 30%!
        </div>
        <div class="profile-title" v-if="!isEditable">
          Спасибо за заявку! Наш юрист свяжется с вами в течении 2-х часов.
          Повторно отправить заявку вы сможете после
          <span class="gradient-text">{{ nextsend }}</span>
        </div>
        <div class="profile-wrapper">
          <form action="" ref="myForm">
            <div class="form-title">
              Данные
            </div>
            <div class="inputs-wrapper">
              <div class="input-wrap">
                <q-select
                  color="green"
                  :rules="lastnameValidate"
                  v-model="lastname"
                  use-input
                  rounded
                  outlined
                  hide-selected
                  @input-value="(val) => (lastname = val)"
                  fill-input
                  input-debounce="0"
                  label="Фамилия"
                  placeholder="Иванов"
                  class="input-req"
                  :options="lastnameSuggestOptions"
                  @filter="fetchLastname"
                  ref="inputLastname"
                  new-value-mode="add"
                  behavior="menu"
                  :disable="!isEditable"
                >
                </q-select>
              </div>
              <div class="input-wrap">
                <q-select
                  color="green"
                  :rules="firstnameValidate"
                  v-model="firstname"
                  use-input
                  rounded
                  outlined
                  hide-selected
                  fill-input
                  class="input-req"
                  @input-value="(val) => (firstname = val)"
                  input-debounce="0"
                  label="Имя"
                  :options="firstnameSuggestOptions"
                  @filter="fetchFirstname"
                  new-value-mode="add"
                  placeholder="Иван"
                  behavior="menu"
                  ref="inputFirstname"
                  :disable="!isEditable"
                >
                </q-select>
              </div>
            </div>

            <div class="inputs-wrapper">
              <div class="input-wrap">
                <q-select
                  color="green"
                  :rules="secondnameValidate"
                  v-model="secondname"
                  use-input
                  rounded
                  outlined
                  hide-selected
                  class="input-req"
                  fill-input
                  @input-value="(val) => (secondname = val)"
                  input-debounce="0"
                  label="Отчество"
                  :options="secondnameSuggestOptions"
                  @filter="fetchSecondname"
                  new-value-mode="add"
                  placeholder="Иванович"
                  behavior="menu"
                  ref="inputSecondname"
                  :disable="!isEditable"
                >
                </q-select>
              </div>
              <div class="input-wrap">
                <q-btn :ripple="false" disable  no-caps>
                  <q-input
                    :rules="phoneValidate"
                    rounded
                    outlined
                    ref="inputPhone"
                    placeholder="+7 (901) 234-5678"
                    v-model="phone"
                    label="Телефон"
                    class="input-margin"
                    mask="+7 (###) ###-####"
                    color="green"
                    disable
                  >
                  </q-input>
                  <q-tooltip
                    anchor="top middle"
                    self="bottom middle"
                    :offset="[10, 10]"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    Сменить номер можно во вкладке "Заявка"
                  </q-tooltip>
                </q-btn>
              </div>
            </div>
            <div class="inputs-wrapper properties-data align-items-center">
              <div class="input-wrap">
                <q-select
                  color="green"
                  v-model="debt"
                  :rules="debtValidate"
                  
                  rounded
                  outlined
                 
                  class="input-req"
                  
                  input-debounce="0"
                  label="Сумма долга"
                  :options="debtItems"
                  emit-value
                  map-options
                  behavior="menu"
                  ref="inputDebt"
                  :disable="!isEditable"
                >
                </q-select>
              </div>
              <div class="input-wrap properties-wrap">
                <div class="step-text">Есть ли у вас имущество</div>
                <div class="input-wrap-property">
                  <q-radio
                    color="green"
                    v-model="property"
                    :val="1"
                    label="Да"
                    :disable="!isEditable"
                  />
                  <q-radio
                    class="radio-custom"
                    color="green"
                    v-model="property"
                    :val="0"
                    label="Нет"
                    :disable="!isEditable"
                  />
                </div>
              </div>
            </div>
            <div class="error">{{ error }}</div>
            <button :disabled="!isEditable" @click="resend" class="resend-btn">
              <a>Отправить заявку</a>
            </button>
            <div class="send-text" v-if="!isEditable">
              Ваша заявка отправлена {{ date }}
            </div>
            <dialog id="resendDialog">
            <div class="resend-dialog__wrapper">
              <div style="text-align: center">
                <div class="resend-dialog__title">Спасибо за заявку!</div>
                <div class="resend-dialog__text">В разделе <span class="gradient-text">Витрина предложений</span> уже доступны займы на выгодных условиях:</div>
              </div>
              <div class="close-btn__wrapper">
                <a class="close-btn">
                <router-link to="/showcase">Перейти</router-link>
                </a>
              </div>
            </div>

            <a @click="closeDialog" aria-label="close" class="x">
              <img src="/img/1close.svg" alt="close" />
            </a>
          </dialog>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "./Loader";
import * as Auth from "@/assets/js/auth.js";
import * as Validate from "@/assets/js/validators.js";
export default {
  name: "Bankru",

  props: {
    setRating: Function,
  },

  components: {
    Loader,
  },

  data: () => ({
    host: location.hostname.replace("lk.", ""),
    loading: true,
    resendForm: false,
    id: "",
    phone: "",
    email: "",
    firstname: "",
    lastname: "",
    secondname: "",
    error: null,
    date: "",
    nextsend: "",
    resendDialog: false,
    creditHistoryId: 0,
    editable: false,
    debt: null,
    isEditable: true,
    property: null,
    debtItems: [
      { value: 0, label: "от 0 до 500 000 рублей" },
      { value: 1, label: "более 500 000 рублей" },
    ],
    propertyItems: [
      { id: 1, value: "Да" },
      { id: 0, value: "Нет" },
    ],
    creditHistoryItems: [
      { id: 0, value: "Нет кредитной истории" },
      { id: 1, value: "Был займ, просрочек не было" },
      { id: 2, value: "Был займ, просрочки были" },
      { id: 3, value: "Открытый займ и просрочки" },
      { id: 4, value: "Работают коллекторы" },
    ],
  }),
  computed: {
    lastnameValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите фамилию",
        (val) => Validate.textOnly(val) || "Введите полностью",
      ];
    },
    firstnameValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите имя",
        (val) => Validate.textOnly(val) || "Введите полностью",
      ];
    },
    secondnameValidate() {
      return [
        (val) => (val !== null && val !== "") || "Введите отчество",
        (val) => Validate.textOnly(val) || "Введите полностью",
      ];
    },
    debtValidate() {
      return [
        (val) => (val !== null && val !== "") || "Выберите сумму долга",
        (val) => Validate.noempty(val) || "Введите полностью",
      ];
    },
  },
  methods: {
    getCreditHistoryString(id) {
      var text;
      this.creditHistoryItems.forEach(function(item) {
        if (id === item.id) {
          text = item.value;
        }
      });
      return text;
    },

    numberFormat(num) {
      return String(num)
        .replace(/[^0-9]/g, "")
        .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ");
    },

    resendInit(e) {
      e.preventDefault();
      this.resendForm = true;
    },

    resend(e) {
      e.preventDefault();
      this.resendForm = false;

      this.creditHistoryId = parseInt(this.creditHistoryId) || 0;
      const isValidDebt = this.$refs.inputDebt.validate();
      const isValidLastname = this.$refs.inputLastname.validate();
      const isValidFirstname = this.$refs.inputFirstname.validate();
      const isValidSecondname = this.$refs.inputSecondname.validate();
      if (!isValidLastname || !isValidFirstname || !isValidSecondname) {
        
        return;
      }
      if(!Validate.noempty(this.property)){
        this.error = "Выберите значение";
      }
      if (!isValidDebt) {
        return;
      }
   
      Auth.load("/client/bid/create", {
        firstname: this.firstname,
        lastname: this.lastname,
        secondname: this.secondname,
        phone: this.phone,
        host: this.host,
        consent: true,
        bankruptcy: {
          debt: this.debt,
          property: this.property,
        },

        creditHistory: this.creditHistoryId,
      }).then(() => {
        const dialog = document.querySelector("#resendDialog");
        dialog.showModal();
        this.isEditable = false;
        Auth.load("/client/profile")
      .then((data) => {
        this.loading = false;
        this.nextsend = data.bidBankruptcy.nextsend;
        this.date = data.bidBankruptcy.date;
        this.setRating(data.rating);
      })
      });
    },
    closeDialog() {
      const dialog = document.querySelector("#resendDialog");
      dialog.close();
    },
  },

  mounted() {
    if (!Auth.getToken()) {
      return this.$router.replace({ name: "Login" });
    }

    Auth.load("/client/profile")
      .then((data) => {
        this.loading = false;
        this.id = data.id;
        this.phone = data.profile.phone;
        this.email = data.profile.email;
        this.firstname = data.profile.firstname;
        this.lastname = data.profile.lastname;
        this.secondname = data.profile.secondname;
        this.dob = data.profile.dob;
        this.birthplace = data.profile.birthplace;
        this.sex = data.profile.sex;
        this.nextsend = data.bidBankruptcy.nextsend;
        this.amount = data.bidBankruptcy.amount;
        this.period = data.bidBankruptcy.period;
        this.creditHistoryId = data.bidBankruptcy.creditHistory;
        this.editable = data.bidBankruptcy.editable;
        this.isEditable = data.bidBankruptcy.editableBankruptcy;
        this.debt = data.bidBankruptcy.debt;
        this.property = +data.bidBankruptcy.property;
        this.date = data.bidBankruptcy.date;
        this.setRating(data.rating);
      })
      .catch(() => {
        Auth.logout();
        this.$router.replace({ name: "Login" });
      });
  },
  watch: {
    property: function() {
      this.error = "";
    },
  },
};
</script>
